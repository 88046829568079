
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  ModalLockHandler
} from '@/shared/components';
import { CookieName } from '@/core/cookies';

@Component({
  name: 'NewStructureModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
    ModalLockHandler
  }
})
export default class NewStructureModal extends Vue {
  @Prop() value: boolean;

  lockCookieName: string = CookieName.DISABLE_NEW_STRUCTURE_MODAL;

  @Emit()
  input (val: boolean): boolean {
    return val;
  }

  @Emit()
  close (): void {
    this.input(false);
  }
}
