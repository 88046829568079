
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  ModalLockHandler
} from '@/shared/components';
import { NeButton } from '@ne/ne-vue2-lib';
import { SubjectMessage, NewSeriesMap } from '@/store/layout-store/types';
import { Location } from 'vue-router';
import { RouteName } from '@/router/models';

@Component({
  name: 'CheckoutNewSeriesModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
    ModalLockHandler,
    NeButton
  }
})
export default class CheckoutNewSeriesModal extends Vue {
  @Prop() value: boolean;
  @Prop() newSeriesData: NewSeriesMap | null;

  get subjectMessages (): SubjectMessage[] {
    return [
      {
        categoryKey: 'reforma-2017-szkola-podstawowa-klasy-4-8,jezyk-angielski,teen-explorer-7-8,klasa-7',
        image: 'subject-books/teen-explorer-new-7.jpg',
        cookie: 'subject-en',
        title: this.$tc('STRUCTURE_STATUS.subject_en_title'),
        descriptionItems: [
          this.$tc('STRUCTURE_STATUS.subject_en_desc_1'),
          this.$tc('STRUCTURE_STATUS.subject_en_desc_2'),
          this.$tc('STRUCTURE_STATUS.subject_en_desc_3')
        ]
      },
      {
        categoryKey: 'reforma-2017-szkola-podstawowa-klasy-4-8,jezyk-niemiecki,meine-deutschtour,klasa-7',
        image: 'subject-books/deutschtour-fit-7.jpg',
        cookie: 'subject-de',
        title: this.$tc('STRUCTURE_STATUS.subject_de_title'),
        descriptionItems: [
          this.$tc('STRUCTURE_STATUS.subject_de_desc_1'),
          this.$tc('STRUCTURE_STATUS.subject_de_desc_2'),
          this.$tc('STRUCTURE_STATUS.subject_de_desc_3')
        ]
      }
    ];
  }

  get subjectMsg (): SubjectMessage | undefined {
    return this.subjectMessages.find(subject => subject.categoryKey === this.$route.params.categoryKey);
  }

  get lockCookieName (): string {
    return `gt_disable_${this.subjectMsg?.cookie}_modal`;
  }

  get hasNewStructure (): boolean {
    return !!this.newSeriesData;
  }

  get newSeriesLink (): Location {
    const categoryKey = this.newSeriesData ? this.newSeriesData.key.replace(/\//g, ',') : '';
    return {
      name: RouteName.TEST_DATABASE,
      params: {
        categoryKey
      }
    };
  }

  getSubjectImg (image: string): void {
    return require(`@/assets/images/${image}`);
  }

  @Emit()
  close (): void {
    this.input(false);
  }

  @Emit()
  input (val: boolean): boolean {
    return val;
  }
}
