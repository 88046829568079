
import { Component, Emit, Vue } from 'vue-property-decorator';
import {
  NeAppWrapper,
  NeButton,
  NeTile
} from '@ne/ne-vue2-lib';

@Component({
  name: 'GeneratorMobileCurtain',
  components: {
    NeAppWrapper,
    NeButton,
    NeTile
  }
})
export default class GeneratorMobileCurtain extends Vue {
  @Emit()
  close (): void {}
}
